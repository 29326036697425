<template>
  <d-card v-if="study" class="study">
    <d-alert
      v-if="msg"
      :show="timeUntilDismissed"
      theme="success"
      dismissible
      @alert-dismissed="timeUntilDismissed = 0"
      @alert-dismiss-countdown="handleTimeChange"
    >
      {{ msg }} {{ timeUntilDismissed }}
    </d-alert>
    <d-card-header class="study-header" :class="{ print: printMode }">
      <div
        v-if="isLab() && !printMode"
        class="
          d-flex
          flex-row
          justify-content-between
          align-items-center
          pt-3
          bg-white
        "
      >
        <a
          class="active arrow-back material-icons"
          @click="$router.push({ name: NAMED_ROUTES.STUDIES })"
        >
          arrow_back
        </a>
        <d-button-group>
          <d-button
            v-if="
              (isNavigator || study.doctorId == $auth.user().relationId) &&
              study.areFromPsp
            "
            id="addClinicalRegister"
            name="addClinicalRegister"
            class="btn-accent"
            type="button"
            :pill="true"
            @click="
              () => {
                $refs.seguimientos.focus()
              }
            "
          >
            <span
              >Seguimientos Clínicos
              <span class="material-icons custom-icon-middle"
                >arrow_downward</span
              ></span
            >
          </d-button>
          <d-button
            v-if="!isNavigator"
            class="btn-accent"
            :disabled="isFieldDisabledLab"
            @click="save(false)"
          >
            Guardar
          </d-button>
          <a
            class="btn btn-secondary"
            :href="`/#${$route.path}?print=true`"
            target="_blank"
          >
            <span class="material-icons"> print </span>
          </a>
        </d-button-group>
      </div>
      <div
        v-else
        class="
          d-flex
          flex-row
          justify-content-between
          align-items-center
          pt-3
          bg-white
        "
      >
        <a
          class="active arrow-back material-icons"
          @click="$router.push({ name: NAMED_ROUTES.STUDIES })"
        >
          arrow_back
        </a>
        <div class="button-for-not-navigator">
          <d-button-group>
            <d-button
              v-if="
                study.doctorId == $auth.user().relationId && study.areFromPsp
              "
              id="addClinicalRegister"
              name="addClinicalRegister"
              class="button-for-not-navigator btn-accent"
              type="button"
              :pill="true"
              @click="
                () => {
                  $refs.seguimientos.focus()
                }
              "
            >
              <span
                >Seguimientos Clínicos
                <span class="material-icons custom-icon-middle"
                  >arrow_downward</span
                ></span
              >
            </d-button>
          </d-button-group>
        </div>
      </div>
      <d-row class="py-2 text-center">
        <d-col class="text-md-left" cols="12" md="4">
          <p v-if="study.envelopeCode" class="mb-0">
            Sobre <strong>{{ study.envelopeCode }}</strong>
          </p>
          <p
            v-for="envelope in additionalEnvelopeCode"
            :key="envelope.pharmaName"
            class="mb-0"
          >
            Sobre {{ envelope.pharmaName }}:
            <strong>{{ envelope.code }}</strong>
          </p>
          <p class="mb-0">
            Estudio #<strong>{{ study.id }}</strong>
          </p>
        </d-col>
        <d-col v-if="study.doctor" class="text-md-left" cols="12" md="6">
          Creado el {{ study.createdAt | moment('DD/MM/YY hh:mm a') }} por '{{
            study.doctor.firstName
          }}
          {{ study.doctor.lastName }}'
        </d-col>
      </d-row>
    </d-card-header>
    <div class="d-card-body text-center h4">
      Paciente
      <h5>
        Apellido: {{ study.patient.lastName }} Nombre:
        {{ study.patient.firstName }}
      </h5>
    </div>
    <d-row class="mb-4" fluid>
      <d-col sm="12" md="6">
        <div class="mx-4 p-4 border-custom position-relative h-100 w-100">
          <h6 class="p-1 px-2">Detalles de Estudio</h6>
          <d-row v-if="isLab() && !isNavigator">
            <d-col>
              <div class="form-group">
                <label>Número de muestra</label>
                <d-form-input
                  v-model="sampleNumber"
                  type="text"
                  :plaintext="isFieldDisabled"
                  class="form-control"
                />
              </div>
            </d-col>
          </d-row>
          <d-row>
            <d-col>
              <div class="form-group">
                <label>Estado</label>
                <d-form-select
                  v-model="studyStatus"
                  :options="studyLabels.status"
                  :disabled="isFieldDisabledLab"
                />
              </div>
            </d-col>
          </d-row>
          <d-row>
            <d-col>
              <div class="form-group">
                <label>Tumor Primario</label>
                <p>
                  {{ currentlySelectedTumor.name }}
                </p>
              </div>
            </d-col>
            <d-col
              v-if="study && currentlySelectedTumor.code === TUMOR_TYPES.LUNG"
            >
              <div class="form-group">
                <label>Diagnóstico</label>
                <p>
                  {{ patientLabels.diagnosis[study.patient.diagnosis] }}
                </p>
              </div>
            </d-col>
            <d-col>
              <div class="form-group">
                <label>{{
                  study.patient.diagnosisStage == null ||
                  patientLabels.diagnosisStage.hasOwnProperty(
                    study.patient.diagnosisStage
                  )
                    ? 'Estadío de Enfermedad'
                    : 'Estadío de Enfermedad al diagnóstico'
                }}</label>
                <p>
                  {{
                    study.patient.diagnosisStage == null ||
                    patientLabels.diagnosisStage.hasOwnProperty(
                      study.patient.diagnosisStage
                    )
                      ? patientLabels.diagnosisStage[
                          study.patient.diagnosisStage
                        ]
                      : getTextForPreScreeningLabel(
                          'stage_at_diagnosis',
                          study.patient.diagnosisStage
                        )
                  }}
                </p>
              </div>
            </d-col>
          </d-row>
          <d-row>
            <d-col>
              <div
                v-if="
                  study.patient.drugsUsedForPriorTreatment != '{}' &&
                  study.patient.drugsUsedForPriorTreatment != '[]' &&
                  study.patient.drugsUsedForPriorTreatment != null &&
                  study.patient.drugsUsedForPriorTreatment != undefined
                "
                class="form-group"
              >
                <label>Drogas usadas para el tratamiento previo</label>
                <p>
                  {{
                    getFormattedTextFromArray(
                      JSON.parse(study.patient.drugsUsedForPriorTreatment).map(
                        (value) => {
                          return getTextForPreScreeningLabel(
                            'drugs_used_for_prior_treatment',
                            value
                          )
                        }
                      )
                    )
                  }}
                </p>
              </div>
            </d-col>
            <d-col
              v-if="study && currentlySelectedTumor.code === TUMOR_TYPES.COLON"
            >
              <div class="form-group">
                <label>Ubicación Tumor</label>
                <p>
                  {{
                    patientLabels.diagnosisLocation[
                      study.patient.diagnosisLocation
                    ]
                  }}
                </p>
              </div>
            </d-col>
          </d-row>
          <d-row>
            <d-col>
              <div class="form-group">
                <label>Línea de tratamiento actual</label>
                <p>
                  {{
                    patientLabels.treatmentStage[
                      study.patient.treatmentStage
                    ] != null
                      ? patientLabels.treatmentStage[
                          study.patient.treatmentStage
                        ]
                      : '-'
                  }}
                </p>
              </div>
            </d-col>
            <d-col>
              <div
                v-if="study.patient.cancerSubtypeAtDiagnosis"
                class="form-group"
              >
                <label>Diagnóstico de subtipo de cancer</label>
                <p>
                  {{
                    getTextForPreScreeningLabel(
                      'cancer_subtype_at_diagnosis',
                      study.patient.cancerSubtypeAtDiagnosis
                    )
                  }}
                </p>
              </div>
            </d-col>
          </d-row>
          <d-row>
            <d-col>
              <div class="form-group">
                <label>Testeo Previo</label>
                <p>
                  {{
                    patientLabels.previousTesting[
                      study.patient.previousTesting
                    ] != null
                      ? patientLabels.previousTesting[
                          study.patient.previousTesting
                        ]
                      : '-'
                  }}
                </p>
              </div>
            </d-col>
          </d-row>
          <d-row>
            <d-col>
              <div class="form-group">
                <label>Tipo de muestra</label>
                <p>
                  {{ currentSampleType }}
                </p>
              </div>
            </d-col>
            <d-col>
              <div v-if="study.patient.currentStage" class="form-group">
                <label>Estadio actual</label>
                <p>
                  {{
                    getTextForPreScreeningLabel(
                      'current_stage',
                      study.patient.currentStage
                    )
                  }}
                </p>
              </div>
            </d-col>
          </d-row>
          <d-row>
            <d-col
              v-if="
                [TUMOR_TYPES.LUNG, TUMOR_TYPES.BLADDER].includes(
                  currentlySelectedTumor.code
                )
              "
            >
              <div class="form-group">
                <label>Condición de Fumador</label>
                <p>
                  {{ patientLabels.smokerStatus[study.patient.smoker] }}
                </p>
              </div>
            </d-col>
            <d-col>
              <div
                v-if="
                  study.patient.drugsUsedForActualTreatment != '{}' &&
                  study.patient.drugsUsedForActualTreatment != '[]' &&
                  study.patient.drugsUsedForActualTreatment != null &&
                  study.patient.drugsUsedForActualTreatment != undefined
                "
                class="form-group"
              >
                <label>Drogas usadas para el tratamiento Actual</label>
                <p>
                  {{
                    getFormattedTextFromArray(
                      JSON.parse(study.patient.drugsUsedForActualTreatment).map(
                        (value) => {
                          return getTextForPreScreeningLabel(
                            'drugs_used_for_prior_treatment',
                            value
                          )
                        }
                      )
                    )
                  }}
                </p>
              </div>
            </d-col>
          </d-row>
          <d-row v-if="currentlySelectedTumor.code === TUMOR_TYPES.BLADDER">
            <d-col>
              <div class="form-group">
                <label>Terapias previas</label>
                <p>
                  {{
                    patientLabels.previousTherapies[
                      study.patient.previousTherapies
                    ]
                  }}
                </p>
              </div>
            </d-col>
            <d-col>
              <div v-if="study.patient.previousTreatments" class="form-group">
                <label>Tratamientos previas</label>
                <p>
                  {{
                    getTextForPreScreeningLabel(
                      'previous_treatments',
                      study.patient.previousTreatments
                    )
                  }}
                </p>
              </div>
            </d-col>
          </d-row>
        </div>
      </d-col>
      <d-col sm="12" md="6">
        <div class="mx-4 border-custom p-4 position-relative h-100">
          <h6 class="p-1 px-2">Detalles de Paciente</h6>
          <div class="mb-3">
            <label>Cobertura Médica</label>
            <p>
              {{
                parseInt(study.patient.insuranceDetails, 10)
                  ? insuranceDetailsConfigurated.content[
                      `${study.patient.insuranceDetails}`
                    ]
                  : study.patient.insuranceDetails
              }}
            </p>
          </div>
          <div class="mb-3">
            <label
              >Documento Nacional De Identidad/Cédula De Identidad/RUT</label
            >
            <p>{{ study.patient.identityDocument }}</p>
          </div>
          <div class="mb-3">
            <label>Fecha de Nacimiento (DD/MM/YYYY)</label>
            <p>{{ study.patient.birthDate | moment('DD/MM/YYYY') }}</p>
          </div>
          <div v-if="study.patient.ethinicity" class="mb-3">
            <label>Etnia</label>
            <p>
              {{ study.patient.ethinicity }}
            </p>
          </div>
          <div class="mb-3">
            <label>Sexo</label>
            <p>
              {{ patientLabels.gender[study.patient.gender] }}
            </p>
          </div>
          <div class="mb-3">
            <label>Email</label>
            <p>{{ study.patient.email }}</p>
          </div>
          <div class="mb-3">
            <label>Teléfono</label>
            <p>{{ study.patient.telephone }}</p>
          </div>
          <div class="mb-3">
            <label>Localidad</label>
            <p>{{ study.patient.administrativeAreaLevel2 }}</p>
          </div>
          <div class="mb-3">
            <label>Provincia o Estado</label>
            <p>{{ study.patient.administrativeAreaLevel1 }}</p>
          </div>
        </div>
      </d-col>
    </d-row>
    <d-row class="mx-4 mb-4" fluid>
      <d-col class="pl-3 pr-3 pt-4 border-custom position-relative">
        <h6 class="p-1 px-2">Resultados</h6>
        <table
          v-if="!study.umbrellaId"
          id="results-table"
          class="table-responsive table table-striped table-borderless"
        >
          <colgroup>
            <col style="width: 5%; max-width: 5%" />
            <col :style="`width:${printMode ? '20' : '15'}%;`" />
            <col style="width: 15%" />
            <col style="width: 20%" />
            <col :style="`width:${printMode ? '40' : '20'}%;`" />
            <col style="width: 25%" />
          </colgroup>
          <thead>
            <th scope="col" class="sticky-column">Estudio</th>
            <th scope="col">Programa</th>
            <th scope="col">Estado</th>
            <th scope="col">Descripción</th>
            <th scope="col">Resultado</th>
            <th v-if="!printMode" id="pdfCol" scope="col">Informe</th>
          </thead>
          <tbody>
            <tr
              v-for="(study_test, study_test_idx) in study.studyTests"
              :key="study_test_idx"
              class="align-self-center"
            >
              <!-- Estudio marca-->
              <td
                v-if="study_test.biomarker"
                class="align-middle sticky-column"
              >
                <study-test-pill :study-test="study_test" :name="null" />
              </td>

              <!-- Programa -->
              <td v-if="study_test.biomarker" class="align-middle">
                {{ getPharmaName(study_test.pharmaId) }}
              </td>

              <!-- Estado de estudio -->
              <td v-if="study_test.biomarker" class="align-middle">
                {{ studyTestLabels.status[study_test.status] }}
              </td>

              <!-- Descripción -->
              <td v-if="study_test.biomarker" class="align-middle form-group">
                <d-form-textarea
                  v-model="study_test.resultDescription"
                  :plaintext="isFieldDisabledLab"
                  :value.prop="study_test.resultDescription"
                  style="resize: none"
                />
              </td>

              <!-- Resultado -->
              <td v-if="study_test.biomarker" class="align-middle">
                <div v-if="study_test.resultId" class="mb-1">
                  {{
                    getStudyResult(
                      studyTestLabels.allResults,
                      study_test.resultId
                    )
                  }}
                </div>
              </td>

              <!-- Archivo -->
              <td
                v-if="!printMode && study_test.biomarker"
                class="align-middle"
              >
                <div v-if="study_test.resultsFile">
                  <a
                    v-if="study_test.resultsFile !== ''"
                    class="d-block"
                    :href="study_test.resultsFile"
                    target="_blank"
                  >
                    Ver Archivo
                  </a>
                  <a
                    v-if="!isFieldDisabledLab"
                    href="#"
                    target="_blank"
                    @click.stop.prevent="updateUploadedFiles(study_test.id)"
                  >
                    Reemplazar Archivo
                  </a>
                </div>
                <div
                  v-if="
                    !isFieldDisabledLab &&
                    (uploaded_files[study_test.id] || !study_test.resultsFile)
                  "
                  class="form-group"
                >
                  <input
                    :ref="'study_test_' + study_test.id"
                    type="file"
                    class="form-control"
                    @change="
                      uploadFiles(
                        'study_test_' + study_test.id,
                        study.studyTests.find((st) => st.id == study_test.id)
                      )
                    "
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Sección de optimus -->

        <!-- <div v-if="item.umbrellaId"> -->
        <table
          v-else
          class="table-responsive table table-striped table-borderless"
        >
          <colgroup>
            <col style="width: 5%; max-width: 5%" />
            <col :style="`width:${printMode ? '20' : '15'}%;`" />
            <col style="width: 15%" />
            <col style="width: 20%" />
            <col :style="`width:${printMode ? '40' : '20'}%;`" />
            <col style="width: 25%" />
          </colgroup>
          <thead>
            <th scope="col" class="sticky-column">Estudio</th>
            <th scope="col">Programa</th>
            <th scope="col">Estado</th>
            <th scope="col">Descripción</th>
            <th scope="col">Resultado</th>
            <th v-if="!printMode" id="pdfCol" scope="col">Informe</th>
          </thead>
          <tbody>
            <tr
              v-for="(study_test, study_test_idx) in bmkStudyTests"
              :key="study_test_idx"
              class="align-self-center"
            >
              <!-- Estudio -->
              <td class="align-middle">
                <study-test-pill
                  :key="study_test.id"
                  :study-test="study_test"
                  :name="null"
                />
              </td>

              <!-- Programa -->
              <td v-if="study_test.biomarker" class="align-middle">
                {{ getPharmaName(study_test.pharmaId) }}
              </td>

              <!-- Estado de estudio -->
              <td v-if="study_test.biomarker" class="align-middle">
                {{ studyTestLabels.status[study_test.status] }}
              </td>

              <!-- Descripción -->
              <td v-if="study_test.biomarker" class="align-middle form-group">
                <d-form-textarea
                  v-model="study_test.resultDescription"
                  :plaintext="isFieldDisabledLab"
                  :value.prop="study_test.resultDescription"
                  style="resize: none"
                />
              </td>

              <!-- Resultado -->
              <td v-if="study_test.biomarker" class="align-middle">
                <div v-if="study_test.resultId" class="mb-1">
                  {{
                    getStudyResult(
                      studyTestLabels.allResults,
                      study_test.resultId
                    )
                  }}
                </div>
              </td>

              <!-- Archivo -->
              <td
                v-if="!printMode && study_test.biomarker"
                class="align-middle"
              >
                <div v-if="study_test.resultsFile">
                  <a
                    v-if="study_test.resultsFile !== ''"
                    class="d-block"
                    :href="study_test.resultsFile"
                    target="_blank"
                  >
                    Ver Archivo
                  </a>
                  <a
                    v-if="!isFieldDisabledLab"
                    href="#"
                    target="_blank"
                    @click.stop.prevent="updateUploadedFiles(study_test.id)"
                  >
                    Reemplazar Archivo
                  </a>
                </div>
                <div
                  v-if="
                    !isFieldDisabledLab &&
                    (uploaded_files[study_test.id] || !study_test.resultsFile)
                  "
                  class="form-group"
                >
                  <input
                    :ref="'study_test_' + study_test.id"
                    type="file"
                    class="form-control"
                    @change="
                      uploadFiles(
                        'study_test_' + study_test.id,
                        study.studyTests.find((st) => st.id == study_test.id)
                      )
                    "
                  />
                </div>
              </td>
            </tr>
            <!--  genes-Pils-->

            <tr
              v-for="(study_test, study_test_idx) in genesPositives"
              :key="study_test_idx"
              class="align-self-center"
            >
              <!-- Estudio -->
              <td class="align-middle">
                <study-test-pill
                  :key="study_test.id"
                  :study-test="study_test"
                  :name="null"
                />
              </td>

              <!-- Programa -->
              <td class="align-middle">
                {{ getPharmaName(study_test.pharmaId) }}
              </td>

              <!-- Estado de estudio -->
              <td class="align-middle">
                {{ studyTestLabels.status[study_test.status] }}
              </td>

              <!-- Descripción -->
              <td class="align-middle form-group">
                <d-form-textarea
                  v-model="study_test.resultDescription"
                  :plaintext="isFieldDisabledLab"
                  :value.prop="study_test.resultDescription"
                  style="resize: none"
                />
              </td>

              <!-- Resultado -->
              <td class="align-middle">
                <div v-if="study_test.resultId" class="mb-1">
                  {{
                    getStudyResult(
                      studyTestLabels.allResults,
                      study_test.resultId
                    )
                  }}
                </div>
              </td>
              <!-- Falso informe para que no quede fea la tabla -->
              <td class="align-middle form-group"></td>
            </tr>

            <!-- breakpoint; umbrellaPills -->

            <tr class="align-self-center">
              <!-- Estudio -->
              <td class="align-middle vertical-align-center">
                <study-test-pill
                  :study-test="null"
                  :name="
                    umbrellasEnabled.find((u) => u.id == study.umbrellaId).name
                  "
                  :is-test-card="true"
                />
              </td>

              <!-- Programa -->
              <td class="align-middle">
                {{ (getPharmaNameForUmbrella(), pharmaNameForOptimusTest) }}
              </td>

              <!-- Estado de estudio -->
              <td class="align-middle">
                {{ studyTestLabels.status[umbrellaTest.status] }}
              </td>

              <!-- Descripción -->
              <td class="align-middle form-group">
                {{ umbrellaTest.resultDescription }}
              </td>

              <!-- Resultado -->
              <td class="align-middle">
                {{
                  getStudyResult(
                    studyTestLabels.allResults,
                    umbrellaTest.resultId
                  )
                }}
              </td>

              <!-- Archivo de test de umbrella-->
              <td
                v-if="!isFieldDisabledLab && !umbrellaTest.resultsFile"
                class="align-middle"
              >
                <div class="form-group">
                  <input
                    :ref="'study_test_' + umbrellaTest.id"
                    type="file"
                    class="form-control"
                    @change="
                      uploadUmbrellaFile(
                        'study_test_' + umbrellaTest.id,
                        study.studyTests.find((st) => st.id == umbrellaTest.id)
                      )
                    "
                  />
                </div>
              </td>
              <td
                v-if="!printMode && umbrellaTest.resultsFile"
                class="align-middle"
              >
                <div>
                  <div v-if="umbrellaTest.resultsFile">
                    <a
                      v-if="umbrellaTest.resultsFile"
                      class="d-block"
                      target="_blank"
                      :href="umbrellaTest.resultsFile"
                    >
                      Ver Archivo
                    </a>
                  </div>
                  <a
                    v-if="!isFieldDisabledLab"
                    :href="umbrellaTest.resultsFile"
                    target="_blank"
                    @click.stop.prevent="updateUploadedFiles(umbrellaTest.id)"
                  >
                    Reemplazar Archivo
                  </a>
                </div>
                <div
                  v-if="
                    !isFieldDisabledLab &&
                    (uploaded_files[umbrellaTest.id] ||
                      !umbrellaTest.resultsFile)
                  "
                  class="form-group"
                >
                  <input
                    :ref="'study_test_' + umbrellaTest.id"
                    type="file"
                    class="form-control"
                    @change="
                      uploadUmbrellaFile(
                        'study_test_' + umbrellaTest.id,
                        study.studyTests.find((st) => st.id == umbrellaTest.id)
                      )
                    "
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <div class="d-card-body">
          <div
            v-if="study.umbrellaId && genesNegatives.length"
            v-d-toggle.umbrella-genes
            class="text-secondary align-items-center"
          >
            <span> {{ genesNegatives.length }} genes con otros resultados</span>
            <span class="material-icons custom-icon"> expand_more</span>
          </div>
          <d-collapse id="umbrella-genes">
            <hr />
            <div
              v-if="genesNegatives.length"
              class="list-group list-group-horizontal"
            >
              <table
                class="table-responsive table table-striped table-borderless"
              >
                <colgroup>
                  <col style="width: 5%; max-width: 5%" />
                  <col :style="`width:${printMode ? '20' : '15'}%;`" />
                  <col style="width: 15%" />
                  <col style="width: 20%" />
                  <col :style="`width:${printMode ? '40' : '20'}%;`" />
                  <col style="width: 25%" />
                </colgroup>
                <thead>
                  <th scope="col" class="sticky-column">Estudio</th>
                  <th scope="col">Programa</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Descripción</th>
                  <th scope="col">Resultado</th>
                </thead>
                <tbody>
                  <tr
                    v-for="(study_test, study_test_idx) in genesNegatives"
                    :key="study_test_idx"
                    class="align-self-center"
                  >
                    <!-- Estudio -->
                    <td class="align-middle">
                      <study-test-pill
                        :key="study_test.id"
                        :study-test="study_test"
                        :name="null"
                      />
                    </td>

                    <!-- Programa -->
                    <td class="align-middle">
                      {{ getPharmaName(study_test.pharmaId) }}
                    </td>

                    <!-- Estado de estudio -->
                    <td class="align-middle">
                      {{ studyTestLabels.status[study_test.status] }}
                    </td>

                    <!-- Descripción -->
                    <td class="align-middle form-group">
                      <d-form-textarea
                        v-model="study_test.resultDescription"
                        :plaintext="isFieldDisabledLab"
                        :value.prop="study_test.resultDescription"
                        style="resize: none"
                      />
                    </td>

                    <!-- Resultado -->
                    <td class="align-middle">
                      <div v-if="study_test.resultId" class="mb-1">
                        {{
                          getStudyResult(
                            studyTestLabels.allResults,
                            study_test.resultId
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
          </d-collapse>
          <hr />
          <div>
            <div
              v-if="study.umbrellaId && genesWithoutResult.length"
              v-d-toggle.genes-without-result
              class="text-secondary align-items-center"
            >
              <span>
                {{ genesWithoutResult.length }}
                genes pendientes
              </span>
              <span class="material-icons custom-icon">expand_more</span>
              <d-collapse id="genes-without-result">
                <p>
                  {{ genesWithoutResultFormatted }}
                </p>
              </d-collapse>
              <hr />
            </div>
          </div>
        </div>
      </d-col>
    </d-row>
    <d-row class="mx-4 mb-4">
      <d-col class="p-4 border-custom position-relative">
        <h6 class="p-1 px-2">Información Adicional</h6>
        <d-row>
          <d-col sm="12" md="6" class="d-flex flex-column align-items-stretch">
            <div
              v-if="isLab()"
              class="form-group flex-grow-1 d-flex flex-column"
            >
              <label>Notas de Laboratorio</label>
              <d-form-textarea
                v-model="labNotes"
                placeholder="No hay notas"
                :value.prop="study.labNotes"
                :plaintext="isFieldDisabled"
                class="flex-grow-1"
                style="resize: none"
              />
            </div>
            <div class="form-group flex-grow-1 d-flex flex-column">
              <label>Comentarios Adicionales</label>
              <d-form-textarea
                v-model="additionalInformation"
                placeholder="No hay comentarios"
                :value.prop="study.additionalInformation"
                :plaintext="isFieldDisabledLab"
                class="flex-grow-1"
                style="resize: none"
              />
            </div>
          </d-col>
          <d-col sm="12" md="6">
            <div class="form-group">
              <label>Horario de Retiro</label>
              <d-form-select
                v-model="pickupTimeRange"
                :options="pickupTimeRangeOptions"
                :disabled="isFieldDisabledLab"
              />
            </div>
            <div class="form-group">
              <label>Dirección de Retiro</label>
              <d-form-textarea
                v-model="pickupAdress"
                :plaintext="isFieldDisabledLab"
                :value.prop="study.pickupAddress"
                :rows="printMode ? 7 : 5"
                style="resize: none"
              />
            </div>
            <div class="form-group">
              <label>Las notificaciones se enviarán a</label>
              <d-form-textarea
                v-model="additionalEmails"
                placeholder="No hay e-mails adicionales a los que enviar notificaciones"
                :plaintext="isFieldDisabledLab"
                :value.prop="study.additionalEmails"
                style="resize: none"
              />
            </div>
          </d-col>
        </d-row>
      </d-col>
    </d-row>
    <d-modal v-if="showModal" :size="'lg'" @close="showModal = false">
      <d-modal-header>
        <d-modal-title v-if="!modalViewMode"
          >{{
            isMedicalRecordNew == true
              ? 'Agregar seguimiento clínico'
              : 'Editar seguimiento clínico'
          }}
        </d-modal-title>
        <d-modal-title v-else> Ver seguimiento clínico </d-modal-title>
        <div class="inline custom-left">
          <d-button-group>
            <d-btn
              :class="medicalRecordStep == 1 ? 'btn-accent' : 'btn-white'"
              :disabled="
                (medicalRecordStep < 1 && !modalViewMode && !modalEditMode) ||
                (modalViewMode &&
                  !medicalOrder.treatmentStage &&
                  !modalEditMode)
              "
              @click="() => (medicalRecordStep = 1)"
              >1</d-btn
            ><d-btn
              :class="medicalRecordStep == 2 ? 'btn-accent' : 'btn-white'"
              :disabled="
                (medicalRecordStep < 2 && !modalViewMode) ||
                (isStepViewable && !modalEditMode && !modalViewMode)
              "
              @click="() => (medicalRecordStep = 2)"
              >2</d-btn
            >
            <d-btn
              :class="medicalRecordStep == 3 ? 'btn-accent' : 'btn-white'"
              :disabled="
                (medicalRecordStep < 3 && !modalViewMode) ||
                (isStepViewable && !modalEditMode && !modalViewMode)
              "
              @click="() => (medicalRecordStep = 3)"
              >3</d-btn
            >
            <d-btn
              :class="medicalRecordStep == 4 ? 'btn-accent' : 'btn-white'"
              :disabled="
                (medicalRecordStep < 4 && !modalViewMode) ||
                (isStepViewable && !modalEditMode && !modalViewMode)
              "
              @click="() => (medicalRecordStep = 4)"
              >4</d-btn
            >
            <d-btn
              :class="medicalRecordStep == 5 ? 'btn-accent' : 'btn-white'"
              :disabled="
                (medicalRecordStep < 5 && !modalViewMode) ||
                (isStepViewable && !modalEditMode && !modalViewMode)
              "
              @click="() => (medicalRecordStep = 5)"
              >5</d-btn
            >
          </d-button-group>
        </div>
      </d-modal-header>

      <d-modal-body>
        <div v-if="medicalRecordStep == 1">
          <div v-if="!modalViewMode">
            <form-label required
              >¿El paciente continua en tratamiento?</form-label
            >
            <multi-button-picker
              :picker-options="previousTestingOptions"
              :default-value="medicalOrder.patientAvailable"
              :disabled="modalViewMode"
              @multi-button-picker-changed="
                patientAvailable = $event
                setMedicalOrderPatientAvailable($event)
                $v.patientAvailable.$touch()
              "
            />
            <p
              v-if="$v.patientAvailable.$invalid && !modalViewMode"
              class="text text-danger"
            >
              Este campo es obligatorio *
            </p>
          </div>
          <div v-if="medicalOrder.patientAvailable == 'no'">
            <form-label required
              >¿Por que no continua en tratamiento?
            </form-label>
            <d-input v-model="treatmentDropMotifs" :disabled="modalViewMode" />
          </div>
          <hr />
        </div>
        <div v-else-if="medicalRecordStep == 2">
          <div class="mx-4 p-4 border-custom position-relative h-80 w-80">
            <h6 class="p-1 px-2">Seguimiento Clínico</h6>
            <d-row>
              <d-col>
                <div
                  v-if="
                    !modalViewMode ||
                    (modalViewMode && !medicalOrderTreatmentStage)
                  "
                >
                  <form-label required>
                    Actualmente, el paciente está recibiendo un tratamiento?
                  </form-label>
                  <multi-button-picker
                    :picker-options="previousTestingOptions"
                    :default-value="
                      modalViewMode
                        ? medicalOrderTreatmentStage
                          ? 'yes'
                          : 'no'
                        : 'no'
                    "
                    :disabled="modalViewMode"
                    @multi-button-picker-changed="
                      setMedicalOrderPatientIsRecevingTreatment($event)
                      patientIsReceavingTreatment = $event
                    "
                  />
                  <p
                    v-if="
                      $v.patientIsReceavingTreatment.$invalid && !modalViewMode
                    "
                    class="text text-danger"
                  >
                    Este campo es obligatorio *
                  </p>
                </div>
                <div
                  v-if="
                    (patientIsReceavingTreatment == 'yes' && !modalViewMode) ||
                    (modalViewMode && medicalOrderTreatmentStage)
                  "
                >
                  <div class="form-group">
                    <form-label required
                      >Línea de tratamiento actual</form-label
                    >
                    <d-form-select
                      v-model="medicalOrderTreatmentStage"
                      :options="treatmentStageOptions"
                      :value="medicalOrderTreatmentStage"
                      :disabled="modalViewMode"
                    >
                      <option value="null" disabled>
                        Seleccione una línea de tratamiento actual
                      </option>
                    </d-form-select>
                    <p
                      v-if="
                        $v.medicalOrderTreatmentStage.$invalid && !modalViewMode
                      "
                      class="text text-danger"
                    >
                      Este campo es obligatorio *
                    </p>
                  </div>
                  <div class="form-group">
                    <form-label required
                      >Drogas utilizadas en el tratamiento actual</form-label
                    >
                    <d-input
                      v-if="modalViewMode == false"
                      v-model="medicalOrderDrugsUsedInActualTreatment"
                      placeholder="Ingrese las drogas separadas por coma"
                    />

                    <div
                      v-else-if="
                        medicalOrder.drugsUsedInActualTreatment != null ||
                        medicalOrder.drugsUsedInActualTreatment != undefined
                      "
                    >
                      <li
                        v-for="val in medicalOrder.drugsUsedInActualTreatment.split(
                          ','
                        )"
                        :key="val"
                      >
                        {{
                          getTextForPreScreeningLabel(
                            'drugs_used_for_actual_treatment',
                            val
                          ) != undefined
                            ? getTextForPreScreeningLabel(
                                'drugs_used_for_actual_treatment',
                                val
                              )
                            : val
                        }}
                      </li>
                    </div>
                    <p v-else>-</p>
                    <p
                      v-if="
                        $v.medicalOrderDrugsUsedInActualTreatment.$invalid &&
                        !modalViewMode
                      "
                      class="text text-danger"
                    >
                      Este campo es obligatorio *
                    </p>
                  </div>
                  <div class="form-group">
                    <form-label required
                      >Fecha del tratamiento actual</form-label
                    >
                    <easy-datepicker
                      :disabled="modalViewMode"
                      :default-value="medicalOrder.treatmentStageDate"
                      @input="
                        (date) => {
                          setMedicalOrderTreatmentStageDate(date)
                          medicalOrderTreatmentStageDate = date
                        }
                      "
                      @invalid-input="
                        ($event) => {
                          setMedicalOrderTreatmentStageDate($event)
                        }
                      "
                    />
                    <p
                      v-if="
                        $v.medicalOrderTreatmentStageDate.$invalid &&
                        !modalViewMode
                      "
                      class="text text-danger"
                    >
                      Este campo es obligatorio *
                    </p>
                  </div>
                </div>
              </d-col>
            </d-row>
          </div>
        </div>
        <div v-else-if="medicalRecordStep == 3">
          <div class="mx-4 p-4 border-custom position-relative h-80 w-80">
            <h6 class="p-1 px-2">Seguimiento Clínico</h6>
            <div
              v-if="
                !modalViewMode ||
                (modalViewMode && !medicalOrderPreviousTreatmentsDate)
              "
              class="form-group"
            >
              <form-label required
                >El paciente recibió alguna línea de tratamiento previa?
              </form-label>
              <multi-button-picker
                :picker-options="willingnessToParticipateClinicalTrialOptions"
                :default-value="
                  modalViewMode
                    ? medicalOrder.previousTreatmentsDate
                      ? 'yes'
                      : 'no'
                    : 'no'
                "
                :disabled="modalViewMode"
                @multi-button-picker-changed="
                  patientIsReceavingPreviousTreatmentLine = $event
                  setMedicalOrderPatientWasReceavingTreatment($event)
                  $v.patientIsReceavingPreviousTreatmentLine.$touch()
                "
              />
              <p
                v-if="
                  $v.patientIsReceavingPreviousTreatmentLine.$invalid &&
                  !modalViewMode
                "
                class="text text-danger"
              >
                Este es un campo obligatorio
              </p>
            </div>
            <div
              v-if="
                (patientIsReceavingPreviousTreatmentLine == 'yes' &&
                  !modalViewMode) ||
                (modalViewMode && medicalOrderPreviousTreatmentsDate)
              "
            >
              <div class="form-group">
                <form-label required
                  >Fecha de administración del último tratamiento
                  previo</form-label
                >
                <easy-datepicker
                  :disabled="modalViewMode"
                  :default-value="medicalOrder.previousTreatmentsDate"
                  @input="
                    (date) => {
                      setMedicalOrderPreviousTreatmentsDate(date)
                      medicalOrderPreviousTreatmentsDate = date
                    }
                  "
                  @invalid-input="
                    ($event) => {
                      setMedicalOrderPreviousTreatmentsDate($event)
                    }
                  "
                />
                <p
                  v-if="
                    $v.medicalOrderPreviousTreatmentsDate.$invalid &&
                    !modalViewMode
                  "
                  class="text text-danger"
                >
                  Este campo es obligatorio *
                </p>
              </div>
              <div class="form-group">
                <form-label required
                  >Sitio de progresión de la enfermedad</form-label
                >
                <d-input
                  v-model="medicalOrderTumorOrganSitesOfProgressiveDisease"
                  :disabled="modalViewMode"
                />
                <p
                  v-if="
                    $v.medicalOrderTumorOrganSitesOfProgressiveDisease
                      .$invalid && !modalViewMode
                  "
                  class="text text-danger"
                >
                  Este campo es obligatorio *
                </p>
              </div>
              <div class="form-group">
                <form-label required
                  >Drogas utilizadas en el último tratamiento</form-label
                >
                <d-input
                  v-if="modalViewMode == false"
                  v-model="medicalOrderDrugsUsedInLastTreatment"
                  :disabled="modalViewMode"
                />
                <div v-else-if="!!medicalOrder.drugsUsedInLastTreatment">
                  <li
                    v-for="val in medicalOrder.drugsUsedInLastTreatment.split(
                      ','
                    )"
                    :key="val"
                  >
                    {{
                      getTextForPreScreeningLabel(
                        'drugs_used_for_prior_treatment',
                        val
                      ) != undefined
                        ? getTextForPreScreeningLabel(
                            'drugs_used_for_prior_treatment',
                            val
                          )
                        : val
                    }}
                  </li>
                </div>
                <p v-else>-</p>
              </div>
              <div class="form-group">
                <form-label required>Respuesta a tratamiento Previo</form-label>
                <multi-button-picker
                  :picker-options="lastTreatmentResponseOptions"
                  :default-value="
                    setNewDefaultValue(
                      lastTreatmentResponseOptions,
                      medicalOrder.responseToLastTreatment
                    )
                  "
                  :disabled="modalViewMode"
                  @multi-button-picker-changed="
                    if ($event != 'other') {
                      setMedicalOrderResponseToLastTreatment($event)
                      $v.medicalOrderResponseToLastTreatment.$touch()
                      medicalOrderResponseToLastTreatment = $event
                      enableOtherField = false
                    } else {
                      setMedicalOrderResponseToLastTreatment('')
                      medicalOrderResponseToLastTreatment = ''
                      enableOtherField = true
                    }
                  "
                />
                <d-input
                  v-if="enableOtherField == true"
                  :value="medicalOrderResponseToLastTreatment"
                  :disabled="modalViewMode"
                  placeholder="Agregue otras respuestas de tratamiento"
                  @input="
                    ($event) => {
                      setMedicalOrderResponseToLastTreatment($event)
                      medicalOrderResponseToLastTreatment = $event
                      $v.medicalOrderResponseToLastTreatment.$touch()
                    }
                  "
                />
                <p
                  v-if="
                    $v.medicalOrderResponseToLastTreatment.$invalid &&
                    !modalViewMode
                  "
                  class="text text-danger"
                >
                  Este campo es obligatorio *
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="medicalRecordStep == 4">
          <div class="mx-4 p-4 border-custom position-relative h-80 w-80">
            <h6 class="p-1 px-2">Seguimiento Clínico</h6>
            <div class="form-group">
              <form-label required
                >Disposición para participar en un ensayo clínico</form-label
              >
              <multi-button-picker
                :picker-options="willingnessToParticipateClinicalTrialOptions"
                :default-value="
                  setNewDefaultValue(
                    willingnessToParticipateClinicalTrialOptions,
                    medicalOrder.willingnessToParticipateClinicalTrial
                  )
                "
                :disabled="modalViewMode"
                @multi-button-picker-changed="
                  setMedicalOrderWillingnessToParticipateClinicalTrial(
                    $event
                  ).then(() =>
                    $v.willingnessToParticipateClinicalTrial.$touch()
                  )
                "
              />
              <p
                v-if="
                  $v.willingnessToParticipateClinicalTrial.$invalid &&
                  !modalViewMode
                "
                class="text text-danger"
              >
                Este campo es obligatorio *
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="medicalRecordStep == 5">
          <h6 v-if="!modalViewMode" class="text-accent text-center">
            Revise los detalles del paciente
          </h6>
          <div class="mx-4 p-4 border-custom position-relative h-80 w-80">
            <h6 class="p-1 px-2">Detalles de Paciente</h6>
            <d-row>
              <d-col>
                <div class="form-group">
                  <label>Nombre</label>
                  <p>{{ medicalOrder.firstName }}</p>
                </div>
                <div class="form-group">
                  <label>Genero</label>
                  <p>{{ medicalOrder.gender }}</p>
                </div>
                <div class="form-group">
                  <label>Fecha de nacimiento</label>
                  <p>{{ medicalOrder.birthDate }}</p>
                </div>
                <div class="form-group">
                  <form-label required>Numero de teléfono </form-label>
                  <d-input
                    v-model="medicalOrderTelephoneNumber"
                    :disabled="modalViewMode"
                  />
                  <p
                    v-if="
                      $v.medicalOrderTelephoneNumber.$invalid && !modalViewMode
                    "
                    class="text text-danger"
                  >
                    Este campo es obligatorio *
                  </p>
                </div>
                <div class="form-group">
                  <form-label required>Provincia</form-label>
                  <d-input
                    v-model="medicalOrderProvince"
                    :disabled="modalViewMode"
                  />
                  <p
                    v-if="$v.medicalOrderProvince.$invalid && !modalViewMode"
                    class="text text-danger"
                  >
                    Este campo es obligatorio *
                  </p>
                </div>
                <div v-if="insuranceDetailsConfigurated" class="form-group">
                  <form-label required>Cobertura Médica</form-label>
                  <d-form-select
                    v-model="medicalOrderHealthInsurance"
                    :disabled="modalViewMode"
                    :options="insuranceDetailsConfigurated.content"
                    :value="medicalOrderHealthInsurance"
                  />
                  <p
                    v-if="
                      $v.medicalOrderHealthInsurance.$invalid && !modalViewMode
                    "
                    class="text text-danger"
                  >
                    Este campo es obligatorio *
                  </p>
                </div>
                <div v-else class="form-group">
                  <form-label required>Cobertura Médica</form-label>
                  <d-form-input
                    v-model="medicalOrderHealthInsurance"
                    :disabled="modalViewMode"
                  />
                  <p
                    v-if="
                      $v.medicalOrderHealthInsurance.$invalid && !modalViewMode
                    "
                    class="text text-danger"
                  >
                    Este campo es obligatorio *
                  </p>
                </div>
              </d-col>
              <d-col>
                <div class="form-group">
                  <label>Apellido</label>
                  <p>{{ medicalOrder.lastName }}</p>
                </div>
                <div class="form-group">
                  <label>Etnia</label>
                  <p>{{ medicalOrder.ethinicity }}</p>
                </div>
                <div class="form-group">
                  <label>NúmeroID</label>
                  <p>
                    {{
                      medicalOrder.sampleNumber
                        ? medicalOrder.sampleNumber
                        : '-'
                    }}
                  </p>
                </div>
                <div class="form-group">
                  <form-label required>Email</form-label>
                  <d-input
                    v-model="medicalOrderEmail"
                    type="email"
                    :disabled="modalViewMode"
                  />
                  <p
                    v-if="!$v.medicalOrderEmail.email"
                    class="text text-danger"
                  >
                    Ingrese una dirección de email válida
                  </p>
                  <p
                    v-if="$v.medicalOrderEmail.$invalid && !modalViewMode"
                    class="text text-danger"
                  >
                    Este campo es obligatorio *
                  </p>
                </div>
                <div class="form-group">
                  <form-label required>Ciudad</form-label>
                  <d-input
                    v-model="medicalOrderCity"
                    :disabled="modalViewMode"
                  />
                  <p
                    v-if="$v.medicalOrderCity.$invalid && !modalViewMode"
                    class="text text-danger"
                  >
                    Este campo es obligatorio *
                  </p>
                </div>
              </d-col>
            </d-row>
            <div v-if="medicalOrder.smokingCondition" class="form-group">
              <form-label required>Condicion de fumador</form-label>
              <multi-button-picker
                :picker-options="smokerStatusOptions"
                :default-value="
                  setNewDefaultValue(
                    smokerStatusOptions,
                    medicalOrder.smokingCondition
                  )
                "
                :disabled="modalViewMode"
                @multi-button-picker-changed="setMedicalOrderSmoker($event)"
              />
            </div>
          </div>
          <div class="mx-4 p-4 border-custom position-relative h-80 w-80">
            <h6 class="p-1 px-2">Información clínica del paciente</h6>
            <d-row>
              <d-col>
                <div class="form-group">
                  <label>Tumor Primario</label>
                  <p>
                    {{
                      primaryTumors.find(
                        (pt) => pt.id === medicalOrder.primaryTumorId
                      )
                        ? primaryTumors.find(
                            (pt) => pt.id === medicalOrder.primaryTumorId
                          ).name
                        : null
                    }}
                  </p>
                </div>
                <div class="form-group">
                  <label>Tipo de muestra</label>
                  <p>
                    {{
                      sampleTypes.find(
                        (st) => st.id === medicalOrder.sampleTypeId
                      )
                        ? sampleTypes.find(
                            (st) => st.id === medicalOrder.sampleTypeId
                          ).label
                        : null
                    }}
                  </p>
                </div>
                <div class="form-group">
                  <form-label required>Subtipo de cancer</form-label>
                  <d-form-select
                    v-model="medicalOrdercancerSubtypeAtDiagnosis"
                    :options="cancerSubtypeAtDiagnosisOptions"
                    :disabled="modalViewMode"
                  >
                    <option :value="null" disabled>
                      Seleccione el subtipo de cancer
                    </option>
                  </d-form-select>
                </div>

                <div v-if="medicalOrder.currentStage" class="form-group">
                  <form-label required>Estadío Actual</form-label>
                  <d-form-select
                    v-model="medicalOrderCurrentStage"
                    :options="currentStageOptions"
                    :disabled="modalViewMode"
                    ><option :value="null" disabled>
                      Seleccione el estadio actual
                    </option></d-form-select
                  >
                  <p
                    v-if="
                      $v.medicalOrderCurrentStage.$invalid && !modalViewMode
                    "
                    class="text text-danger"
                  >
                    Este campo es obligatorio *
                  </p>
                </div>
              </d-col>
              <d-col>
                <div class="form-group">
                  <form-label required>Testeo previo</form-label>
                  <multi-button-picker
                    :picker-options="previousTestingOptions"
                    :default-value="
                      setNewDefaultValue(
                        previousTestingOptions,
                        medicalOrder.previousTesting
                      )
                    "
                    :disabled="modalViewMode"
                    @multi-button-picker-changed="
                      setMedicalOrderPreviousTesting($event).then(() =>
                        forceUpdateForm($event)
                      )
                    "
                  />
                  <p
                    v-if="
                      $v.medicalOrderPreviousTesting.$invalid && !modalViewMode
                    "
                    class="text text-danger"
                  >
                    Este campo es obligatorio *
                  </p>
                </div>
                <div class="form-group">
                  <form-label required>Estadio de diagnóstico</form-label>
                  <d-form-select
                    v-model="medicalOrderDiagnosisStage"
                    :options="diagnosisStageOptions"
                    :disabled="modalViewMode"
                  >
                    <option :value="null">
                      Seleccione un subtipo de diagnóstico
                    </option>
                  </d-form-select>
                </div>
                <div class="form-group">
                  <form-label required>Tratamientos Previos</form-label>
                  <d-form-select
                    v-model="medicalOrderPreviousTreatments"
                    :options="previousTreatmentsOptions"
                    :disabled="modalViewMode"
                  >
                    <option :value="null" disabled>
                      Seleccione el tratamiento previo
                    </option>
                  </d-form-select>
                </div>
              </d-col>
            </d-row>
          </div>
        </div></d-modal-body
      >
      <d-modal-footer
        ><d-button
          type="button"
          class="btn-secondary btn-pill"
          modal-view-mode="false"
          @click="showModal = false"
        >
          {{ modalViewMode ? 'Cerrar' : 'Cancelar' }}
        </d-button>
        <d-button
          v-if="medicalRecordStep != 1"
          class="btn-accent btn-pill"
          @click="() => (medicalRecordStep -= 1)"
          >Atras</d-button
        >
        <d-button
          v-if="medicalRecordStep != 5 && !medicalOrder.treatmentDropMotifs"
          class="btn-accent btn-pill"
          @click="
            () => {
              if (medicalOrder.patientAvailable === 'yes') {
                canProceed(medicalRecordStep)
              } else if (modalViewMode || modalEditMode) {
                medicalRecordStep += 1
              }
            }
          "
          >Siguiente</d-button
        >
        <d-button
          v-if="
            !modalViewMode &&
            (medicalRecordStep == 5 ||
              (medicalRecordStep == 1 && !!medicalOrder.treatmentDropMotifs))
          "
          type="button"
          class="btn-accent btn-pill"
          :disabled="$v.$invalid"
          @click="
            saveNewMedicalOrder().then((response) => {
              showModal = false
              setFocusOnOrders()
              modalViewMode = false
              modalEditMode = false
            })
          "
        >
          Guardar
        </d-button></d-modal-footer
      >
    </d-modal>
    <d-card-body
      v-if="study.medicalRecords.length > 0 || sortedMedicalRecords.length > 0"
      class="pl-3 pr-3 pt-4"
    >
      <h5 id="seguimientos" ref="seguimientos" tabindex="0">
        Seguimientos clínicos
      </h5>
      <div class="table-responsive">
        <table class="table table-condensed">
          <thead>
            <th>Seguimiento #</th>
            <th>Fecha de Seguimiento</th>
            <th>Fecha de carga</th>
            <th>Ver seguimiento</th>
            <th>Editar seguimiento</th>
          </thead>
          <tbody>
            <tr
              v-for="record in sortedMedicalRecords"
              :key="record.createdAt"
              class="align-items-center"
            >
              <td class="align-middle">
                {{ record.ordinalNumber }}
              </td>
              <td class="align-middle">
                {{
                  record.initialMedicalRecordDate | moment('DD/MM/YY hh:mm a')
                }}
              </td>
              <td class="align-middle">
                {{ record.createdAt | moment('DD/MM/YY hh:mm a') }}
              </td>
              <td class="align-middle">
                <button
                  v-if="record.createdAt"
                  type="button"
                  class="btn btn-accent"
                  @click="
                    () => {
                      showModal = true
                      editMedicalOrder(record)
                      modalViewMode = true
                      if (!canShowFirstPageOfMedicalRecord) {
                        showFirstPageOfMedicalRecord(2)
                      } else {
                        showFirstPageOfMedicalRecord(1)
                      }
                    }
                  "
                >
                  <span class="material-icons">preview</span>
                </button>
                <span v-else>-</span>
              </td>
              <td v-if="record.createdAt" class="align-middle">
                <button
                  type="button"
                  class="btn btn-accent"
                  @click="
                    () => {
                      showModal = true
                      editMedicalOrder(record)
                      modalViewMode = false
                      modalEditMode = true
                      medicalRecordStep = 1
                    }
                  "
                >
                  <span class="material-icons">edit</span>
                </button>
              </td>
              <td v-else class="align-middle">
                <button
                  type="button"
                  class="btn btn-accent"
                  @click="
                    () => {
                      showModal = true
                      loadMedicalOrderInformation(
                        (index = record.ordinalNumber)
                      )
                      modalViewMode = false
                      modalEditMode = false
                      setHandlerMedicalOrderPreviousTesting(
                        medicalOrder.previousTesting
                      )
                      medicalRecordStep = 1
                    }
                  "
                >
                  Agregar seguimiento clínico
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </d-card-body>
  </d-card>
</template>

<script>
import EasyDatepicker from '@/components/common/EasyDatepicker'
import { mapActions, mapGetters, mapState } from 'vuex'
import { required, email, requiredIf } from 'vuelidate/lib/validators'
import { getOptionsFor } from '@/utils'
import { TUMOR_TYPES, ROW_STATUS } from '@/utils/constants'
import { getFormattedText } from '@/utils'
import StudyTestPill from '@/components/StudyTestPill.vue'
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin'
import MultiButtonPicker from '@/components/MultiButtonPicker'
import FormLabel from '@/components/Form/Label'
import Vue from 'vue'

export default {
  name: 'StudiesView',
  components: { StudyTestPill, MultiButtonPicker, FormLabel, EasyDatepicker },
  mixins: [PlutonBaseMixin],
  data() {
    return {
      TUMOR_TYPES,
      ROW_STATUS,
      form_fields: {},
      uploaded_files: {},
      item: null,
      msg: null,
      timeUntilDismissed: 0,
      duration: 3,
      pharmaNameForOptimusTest: null,
      showModal: false,
      modalViewMode: false,
      modalEditMode: false,
      medicalOrderPreviousTesting: null,
      medicalRecordStep: 1,
      medicalOrderTreatmentStageDate: null,
      patientIsReceavingPreviousTreatmentLine: null,
      medicalOrderPreviousTreatmentsDate: null,
      patientIsReceavingTreatment: null,
      medicalOrderResponseToLastTreatment: null,
      enableOtherField: false,
      patientAvailable: '',
    }
  },
  validations: {
    medicalOrderTelephoneNumber: {
      required: requiredIf(function () {
        return !this.medicalOrder.treatmentDropMotifs
      }),
    },
    medicalOrderProvince: {
      required: requiredIf(function () {
        return !this.medicalOrder.treatmentDropMotifs
      }),
    },
    medicalOrderHealthInsurance: {
      required: requiredIf(function () {
        return !this.medicalOrder.treatmentDropMotifs
      }),
    },
    medicalOrderEmail: {
      required: requiredIf(function () {
        return !this.medicalOrder.treatmentDropMotifs
      }),
      email,
    },
    medicalOrderCity: {
      required: requiredIf(function () {
        return !this.medicalOrder.treatmentDropMotifs
      }),
    },
    medicalOrderResponseToLastTreatment: {
      required: requiredIf(function () {
        return (
          !this.medicalOrder.treatmentDropMotifs &&
          this.patientIsReceavingPreviousTreatmentLine == 'yes'
        )
      }),
    },
    medicalOrderTumorOrganSitesOfProgressiveDisease: {
      required: requiredIf(function () {
        return (
          !this.medicalOrder.treatmentDropMotifs &&
          this.patientIsReceavingPreviousTreatmentLine == 'yes'
        )
      }),
    },
    willingnessToParticipateClinicalTrial: {
      required: requiredIf(function () {
        return !this.medicalOrder.treatmentDropMotifs
      }),
    },
    medicalOrderPreviousTesting: {
      required: requiredIf(function () {
        return !this.medicalOrder.treatmentDropMotifs
      }),
    },
    patientAvailable: {
      required,
    },
    patientIsReceavingTreatment: {
      required: requiredIf(function () {
        return !this.medicalOrder.treatmentDropMotifs
      }),
    },
    medicalOrderCurrentStage: {
      required: requiredIf(function () {
        return this.currentlySelectedTumor.code === TUMOR_TYPES.BLADDER
      }),
    },
    medicalOrderTreatmentStage: {
      required: requiredIf(function () {
        return (
          !this.medicalOrder.treatmentDropMotifs &&
          this.patientIsReceavingTreatment === 'yes'
        )
      }),
    },
    medicalOrderDrugsUsedInActualTreatment: {
      required: requiredIf(function () {
        return (
          !this.medicalOrder.treatmentDropMotifs &&
          this.patientIsReceavingTreatment === 'yes'
        )
      }),
    },
    medicalOrderTreatmentStageDate: {
      required: requiredIf(function () {
        return (
          !this.medicalOrder.treatmentDropMotifs &&
          this.patientIsReceavingTreatment === 'yes'
        )
      }),
    },
    patientIsReceavingPreviousTreatmentLine: {
      required: requiredIf(function () {
        return !this.medicalOrder.treatmentDropMotifs
      }),
    },
    medicalOrderPreviousTreatmentsDate: {
      required: requiredIf(function () {
        return (
          !this.medicalOrder.treatmentDropMotifs &&
          this.patientIsReceavingPreviousTreatmentLine == 'yes'
        )
      }),
    },
  },
  computed: {
    ...mapGetters('countries', ['sampleTypes']),
    ...mapState('labelMappings', [
      'studyLabels',
      'studyTestLabels',
      'patientLabels',
      'preScreeningLabels',
    ]),
    ...mapGetters('pharmas', ['pharmas']),
    ...mapGetters('primaryTumors', ['primaryTumors']),
    ...mapState('biomarkers', ['biomarkersSetting']),
    ...mapState('studies', ['study', 'umbrellaSettings', 'medicalOrder']),
    ...mapGetters('panels', ['genesEnabled', 'umbrellasEnabled']),
    ...mapState('panels', ['genesForThisPanel', 'genes']),
    ...mapState('results', ['results']),
    ...mapState('dbFrontendConfigurations', ['frontEndConfigs']),
    ...mapGetters('results', ['results']),
    ...mapGetters('studies', ['isMedicalRecordNew', 'medicalOrder']),
    ...mapGetters('auth', ['user']),
    isStepViewable() {
      switch (this.medicalRecordStep) {
        case 1:
          return true
        case 2:
          return (
            !this.medicalOrder.treatmentStage &&
            !this.medicalOrder.drugsUsedInActualTreatment &&
            !this.medicalOrder.treatmentStageDate
          )
        case 3:
          return (
            !this.medicalOrder.previousTreatmentsDate &&
            !this.patientIsReceavingPreviousTreatmentLine &&
            !this.medicalOrder.tumorOrganSitesOfProgressiveDisease
          )
        case 4:
          return !this.willingnessToParticipateClinicalTrial
        default:
          return false
      }
    },
    FORTREA() {
      return this.pharmas.find((ph) => ph.code.startsWith('FORTREA'))
    },
    medicalOrderTreatmentStage: {
      get() {
        return this.medicalOrder.treatmentStage
      },
      set(val) {
        this.setMedicalOrderTreatmentStage(val)
      },
    },
    treatmentDropMotifs: {
      get() {
        return this.medicalOrder.treatmentDropMotifs
      },
      set(val) {
        this.setMedicalOrderTreatmentDropMotifs(val)
      },
    },
    canShowFirstPageOfMedicalRecord() {
      if (this.modalEditMode == false)
        return !!this.medicalOrder.treatmentDropMotifs
      return (
        !!this.medicalOrder.treatmentDropMotifs &&
        !this.medicalOrder.patientAvailable
      )
    },
    groupedStudyConditionsToAddMedicalRecords() {
      /*
      Actual conditions:
      · PSP (all of FORTREA)
      · Single genes(Biomarkers) from Argentina in lung tumor type
      */
      let fortrea = this.pharmas.find((ph) => ph.code.startsWith('FORTREA'))

      let areThisStudyFromPSP = fortrea
        ? this.study.studyTests.find((st) => st.pharmaId === fortrea.id)
        : null

      let areThisStudyBiomarkersTestFromArgentinaAndLungTumorrtype =
        this.currentlySelectedTumor.code === this.TUMOR_TYPES.LUNG &&
        this.$auth.user().country.name.startsWith('Argentina') &&
        (this.study.umbrellaId != null ||
          this.study.studyTests.every((st) => st.testId != null))

      return (
        areThisStudyFromPSP ||
        areThisStudyBiomarkersTestFromArgentinaAndLungTumorrtype
      )
    },

    insuranceDetailsConfigurated() {
      return this.$store.state.dbFrontendConfigurations.frontEndConfigs.find(
        (config) =>
          config.countryId == this.study.doctor.countryId &&
          config.frontendFieldId == 'insurance_details'
      )
    },
    medicalOrderTelephoneNumber: {
      get() {
        return this.medicalOrder.telephoneNumber
      },
      set(val) {
        this.setMedicalOrderTelephoneNumber(val)
      },
    },
    medicalOrderProvince: {
      get() {
        return this.medicalOrder.province
      },
      set(val) {
        this.setMedicalOrderProvince(val)
      },
    },
    medicalOrderHealthInsurance: {
      get() {
        return this.medicalOrder.healthInsurance
      },
      set(val) {
        this.setMedicalOrderHealthInsurance(val)
      },
    },
    medicalOrderEmail: {
      get() {
        return this.medicalOrder.email
      },
      set(val) {
        this.setMedicalOrderEmail(val)
      },
    },
    medicalOrderCity: {
      get() {
        return this.medicalOrder.city
      },
      set(val) {
        this.setMedicalOrderCity(val)
      },
    },
    medicalOrdercancerSubtypeAtDiagnosis: {
      get() {
        return this.medicalOrder.cancerSubtypeAtDiagnosis
      },

      set(val) {
        this.setMedicalOrderCancerSubtypeAtDiagnosis(val)
      },
    },
    medicalOrderDrugsUsedInLastTreatment: {
      get() {
        return this.medicalOrder.drugsUsedInLastTreatment
      },
      set(val) {
        this.setMedicalOrderDrugsUsedInLastTreatment(val)
      },
    },
    medicalOrderDrugsUsedInActualTreatment: {
      get() {
        return this.medicalOrder.drugsUsedInActualTreatment
      },
      set(val) {
        this.setMedicalOrderDrugsUsedInActualTreatment(val)
      },
    },
    medicalOrderPreviousTreatments: {
      get() {
        return this.medicalOrder.previousTreatments
      },
      set(val) {
        this.setMedicalOrderPreviousTreatments(val)
      },
    },
    medicalOrderDiagnosisStage: {
      get() {
        return this.medicalOrder.diagnosisStage
      },
      set(val) {
        this.setmedicalOrderDiagnosisStage(val)
      },
    },
    // medicalOrderResponseToLastTreatment: {
    //   get() {
    //     return this.medicalOrder.responseToLastTreatment
    //   },
    //   set(val) {
    //     this.setMedicalOrderResponseToLastTreatment(val)
    //   },
    // },
    medicalOrderTumorOrganSitesOfProgressiveDisease: {
      get() {
        return this.medicalOrder.tumorOrganSitesOfProgressiveDisease
      },
      set(val) {
        this.setMedicalOrderTumorOrganSitesOfProgressiveDisease(val)
      },
    },
    medicalOrderCurrentStage: {
      get() {
        return this.medicalOrder.currentStage
      },
      set(val) {
        this.setMedicalOrderCurrentStage(val)
      },
    },
    willingnessToParticipateClinicalTrial() {
      return this.medicalOrder.willingnessToParticipateClinicalTrial
    },
    smokerStatusOptions() {
      return getOptionsFor(this.patientLabels.smokerStatus)
    },
    previousTestingOptions() {
      return getOptionsFor(this.patientLabels.previousTesting)
    },
    treatmentStageOptions() {
      return getOptionsFor(this.patientLabels.treatmentStage)
    },
    cancerSubtypeAtDiagnosisOptions() {
      return getOptionsFor(
        this.preScreeningPatientValuesForThisPrimaryTumor[
          'cancer_subtype_at_diagnosis'
        ]
      )
    },
    previousTreatmentsOptions() {
      return getOptionsFor(
        this.preScreeningPatientValuesForThisPrimaryTumor['previous_treatments']
      )
    },
    diagnosisStageOptions() {
      return getOptionsFor(
        this.preScreeningPatientValuesForThisPrimaryTumor['stage_at_diagnosis']
      )
    },
    currentStageOptions() {
      return getOptionsFor(
        this.preScreeningPatientValuesForThisPrimaryTumor['current_stage']
      )
    },
    willingnessToParticipateClinicalTrialOptions() {
      return getOptionsFor(
        this.patientLabels.willingnessToParticipateClinicalTrialLabels
      )
    },
    lastTreatmentResponseOptions() {
      return getOptionsFor(this.patientLabels.lastTreatmentResponse)
    },
    preScreeningPatientValuesForThisPrimaryTumor() {
      let primaryTumor = this.primaryTumors.find(
        (pt) => pt.id === this.study.primaryTumorId
      )
      return new Object(
        this.$store.state.labelMappings.preScreeningLabels[
          `${primaryTumor.code}`
        ]
      )
    },

    isNavigator() {
      return this.$auth
        .user()
        .roles.some((roleType) => roleType.roleType === 'navigator')
    },
    arePositiveGenes() {
      return this.$store.state.studies.study.studyTests.find(
        (st) =>
          st.genes != null &&
          ['positive'].includes(
            this.results.find((r) => r.id == st.resultId).statisticsGrouper
          )
      )
    },
    umbrellaTest() {
      if (
        this.$store.state.studies.study.studyTests.find(
          (st) =>
            st.genes != null && st.resultId != null && this.arePositiveGenes
        ) != undefined
      )
        return this.$store.state.studies.study.studyTests.find(
          (st) => st.genes != null && st.resultsFile && st.resultId
        ) != undefined
          ? this.$store.state.studies.study.studyTests.find(
              (st) => st.genes != null && st.resultsFile
            )
          : this.$store.state.studies.study.studyTests.find(
              (st) =>
                st.genes != null &&
                ['positive'].includes(
                  this.results.find((r) => r.id == st.resultId)
                    .statisticsGrouper
                )
            )
      else if (
        this.$store.state.studies.study.studyTests.find(
          (st) =>
            st.genes != null && st.resultId != null && !this.arePositiveGenes
        ) != undefined
      ) {
        return this.$store.state.studies.study.studyTests.find(
          (st) => st.genes != null && st.resultsFile && st.resultId
        ) != undefined
          ? this.$store.state.studies.study.studyTests.find(
              (st) => st.genes != null && st.resultsFile
            )
          : this.$store.state.studies.study.studyTests.find(
              (st) =>
                st.genes != null &&
                ['negative'].includes(
                  this.results.find((r) => r.id == st.resultId)
                    .statisticsGrouper
                )
            )
      }
      return this.$store.state.studies.study.studyTests.find(
        (st) => st.genes != null
      )
    },
    testWithFileRoute() {
      return this.study.studyTests.find((st) => st.resultsFile != null)
    },
    sampleNumber: {
      get() {
        return this.study.sampleNumber
      },
      set(number) {
        this.setSampleNumber(number)
      },
    },
    studyStatus: {
      get() {
        return this.study.status
      },
      set(status) {
        this.setStudyStatus(status)
      },
    },
    labNotes: {
      get() {
        return this.study.labNotes
      },
      set(text) {
        this.setLabNotes(text)
      },
    },
    additionalInformation: {
      get() {
        return this.study.additionalInformation
      },
      set(text) {
        this.setAdittionalInformation(text)
      },
    },
    pickupTimeRange: {
      get() {
        return this.study.pickupTimeRange
      },
      set(timeOption) {
        this.setPickupTimeRange(timeOption)
      },
    },
    pickupAdress: {
      get() {
        return this.study.pickupAddress
      },
      set(text) {
        this.setPickupAdress(text)
      },
    },
    additionalEmails: {
      get() {
        return this.study.additionalEmails
      },

      set(mail) {
        this.setAdittionalEmails(mail)
      },
    },
    testDescriptionField: {
      get() {
        return this.study.studyTests.resultDescription
      },
      set(studytest_id, description) {
        this.setStudyTestDescription(studytest_id, description)
      },
    },
    sortedMedicalRecords() {
      let copyData = JSON.parse(JSON.stringify(this.study.medicalRecords))

      //  study.mrPendings[idx].initialMedicalRecordDate
      let withOrdinalNumber = copyData.filter(
        (item) => item.ordinalNumber != null
      )
      let withoutOrdinalNumber = copyData.filter(
        (item) => item.ordinalNumber == null
      )
      let copyPendingsMedicalRecords = JSON.parse(
        JSON.stringify(this.study.mrPendings)
      )
      // Ordenar registros sin ordinalNumber por createdAt
      withoutOrdinalNumber.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      )

      // Ordenar registros con ordinalNumber por ordinalNumber
      withOrdinalNumber.sort((a, b) => a.ordinalNumber - b.ordinalNumber)

      // Combinar los registros: insertar los registros con ordinalNumber en las posiciones correctas
      withOrdinalNumber.forEach((item) => {
        // Si el ordinalNumber es mayor que la cantidad actual de registros sin ordinalNumber, se pone al final
        if (item.ordinalNumber >= withoutOrdinalNumber.length) {
          withoutOrdinalNumber.push(item)
        } else {
          // Insertar en la posición adecuada basada en el ordinalNumber
          withoutOrdinalNumber.splice(item.ordinalNumber, 0, item)
        }
      })
      //loop para agregar los "ordinal_numbers" a los seguimientos viejos de manera temporal
      withoutOrdinalNumber.forEach((element, index) => {
        if (!element.ordinalNumber) {
          element.ordinalNumber = index
        }
      })

      //loop para agregar en orden las fechas teóricas a cada seguimiento!
      withoutOrdinalNumber.forEach((value) => {
        let valueToSplice = copyPendingsMedicalRecords[value.ordinalNumber - 1]

        value.initialMedicalRecordDate = valueToSplice.initialMedicalRecordDate
        copyPendingsMedicalRecords.splice(
          copyPendingsMedicalRecords.indexOf(valueToSplice),
          1
        )
      })
      //re-asignar indices de las fechas que quedan
      copyPendingsMedicalRecords.forEach((val) => {
        let idx = this.study.mrPendings.findIndex(
          (value) =>
            value.initialMedicalRecordDate == val.initialMedicalRecordDate
        )
        withoutOrdinalNumber.push({ ordinalNumber: idx + 1, ...val }) //add new Value
      })
      withoutOrdinalNumber.sort((a, b) => a.ordinalNumber - b.ordinalNumber)

      return withoutOrdinalNumber
    },
    bmkStudyTests() {
      return this.$store.state.studies.study.studyTests.filter(
        (st) => st.biomarker != null
      )
    },
    biomarkersWithResult() {
      return this.bmkStudyTests.filter((g) => g.resultId != null)
    },
    genesWithoutResult() {
      return this.umbrellaStudyTests.filter(
        (st) => !this.genesWithResult.includes(st)
      )
    },
    genesWithoutResultFormatted() {
      return getFormattedText(
        this.genesWithoutResult.map((gene) => gene.testCode)
      )
    },
    bmksWithoutResult() {
      return this.bmkStudyTests.filter(
        (st) => !this.biomarkersWithResult.includes(st)
      )
    },
    bmksWithoutResultFormatted() {
      return getFormattedText(this.bmksWithoutResult.map((bmk) => bmk.testCode))
    },
    umbrellaStudyTests() {
      return this.$store.state.studies.study.studyTests.filter(
        (st) => st.genes != null
      )
    },
    genesWithResult() {
      return this.umbrellaStudyTests.filter((g) => g.resultId != null)
    },
    genesPositives() {
      return this.genesWithResult.filter((g) =>
        this.geneResult(g.resultId)
          ? this.geneResult(g.resultId).statisticsGrouper == 'positive'
          : []
      )
    },
    genesNegatives() {
      return this.genesWithResult.filter((g) =>
        this.geneResult(g.resultId)
          ? this.geneResult(g.resultId).statisticsGrouper !== 'positive'
          : []
      )
    },
    biomarkersPositives() {
      return this.biomarkersWithResult.filter(
        (b) => this.geneResult(b.resultId).statisticsGrouper == 'positive'
      )
    },
    currentlySelectedTumor() {
      const tumor = this.primaryTumors.find(
        (tumor) => tumor.id === this.study.primaryTumorId
      )
      if (!tumor)
        return { name: 'Tumor primario no identificado', code: 'unknown' }
      return tumor
    },
    printMode() {
      return !!this.$route.query.print
    },
    pickupTimeRangeOptions() {
      return getOptionsFor(this.studyLabels.pickupTimeRange)
    },
    currentSampleType() {
      const notFoundMsg = 'No hay un tipo de muestra definido para este estudio'
      if (!this.study) return notFoundMsg
      const sampleType = this.sampleTypes.find(
        (sampleType) => sampleType.id === this.study.sampleTypeId
      )
      if (!sampleType) return notFoundMsg
      return sampleType.label
    },
    isFieldDisabled() {
      return this.printMode || this.user.rowStatus !== ROW_STATUS.ENABLED
    },
    isFieldDisabledLab() {
      return (
        this.isNavigator ||
        !this.isLab() ||
        this.printMode ||
        this.user.rowStatus !== ROW_STATUS.ENABLED
      )
    },
    additionalEnvelopeCode() {
      const pharmaNamesById = {}
      this.pharmas.forEach(
        (pharma) => (pharmaNamesById[pharma.id] = pharma.name)
      )
      return [
        ...new Map(
          this.$store.state.studies.study.studyTests
            .filter(
              (studyTest) =>
                !!studyTest.pharmaEnvelopeCode &&
                studyTest.pharmaEnvelopeCode !==
                  this.$store.state.studies.study.envelopeCode
            )
            .map((studyTest) => [
              studyTest.pharmaId,
              {
                pharmaName: pharmaNamesById[studyTest.pharmaId],
                code: studyTest.pharmaEnvelopeCode,
              },
            ])
        ).values(),
      ]
    },
  },
  watch: {
    'medicalOrder.patientWasReceavingTreatment'(val) {
      this.patientIsReceavingPreviousTreatmentLine = val
    },
    '$store.state.filters': {
      handler: async function (newFilters) {
        this.fetchStudyTestLabels({ filters: newFilters })
      },
      deep: true,
      immediate: true,
    },

    patientAvailable: {
      handler: function (val) {
        if (val == 'yes') {
          this.setMedicalOrderTreatmentDropMotifs(null)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.setLoading(true)
    this.fetchStudyLabels()
    this.fetchStudyTestLabels()
    this.fetchPatientLabels()
    await this.getUmbrellaTestSettings()
    await this.getGenes()
    await this.getStudy(this.$route.params.id).then(() => {
      this.setLoading(false)
    })

    await this.getPharmas()
    await this.getPrimaryTumors()
    this.getSampleTypes()
    await this.getBiomarkersSetting()
    await this.getUmbrellas()
    await this.getResults()
    await this.getPreScreeningLabels()
    await this.getFrontEndConfigurationsForCreateStudy()
    if (this.$route.query.print) window.print()
    const scrollResults = () => {
      if (window.innerWidth < 768) {
        const objDiv = document.getElementById('results-table')
        objDiv.scrollLeft = objDiv.scrollWidth
      }
    }
    setTimeout(scrollResults, 1000)
    window.onresize = scrollResults
  },
  methods: {
    ...mapActions('primaryTumors', ['getPrimaryTumors']),
    ...mapActions('countries', ['getSampleTypes']),
    ...mapActions('pharmas', ['getPharmas']),
    ...mapActions('labelMappings', [
      'fetchStudyLabels',
      'fetchStudyTestLabels',
      'fetchPatientLabels',
      'getPreScreeningLabels',
    ]),
    ...mapActions('biomarkers', ['getBiomarkersSetting']),
    ...mapActions('studies', [
      'getUmbrellaTestSettings',
      'loadMedicalOrderInformation',
      'setMedicalOrderTelephoneNumber',
      'setMedicalOrderProvince',
      'setMedicalOrderHealthInsurance',
      'setMedicalOrderEmail',
      'setMedicalOrderCity',
      'setMedicalOrderSmoker',
      'setMedicalOrderPreviousTesting',
      'setMedicalOrderPatientAvailable',
      'setMedicalOrderCancerSubtypeAtDiagnosis',
      'setMedicalOrderTreatmentDropMotifs',
      'setMedicalOrderDrugsUsedInLastTreatment',
      'setMedicalOrderPreviousTreatments',
      'setmedicalOrderDiagnosisStage',
      'setMedicalOrderResponseToLastTreatment',
      'setMedicalOrderTumorOrganSitesOfProgressiveDisease',
      'setMedicalOrderWillingnessToParticipateClinicalTrial',
      'setMedicalOrderCurrentStage',
      'setMedicalOrderPatientIsRecevingTreatment',
      'setMedicalOrderTreatmentStage',
      'setMedicalOrderDrugsUsedInActualTreatment',
      'setMedicalOrderTreatmentStageDate',
      'setMedicalOrderPreviousTreatmentsDate',
      'setMedicalOrderPatientWasReceavingTreatment',
      'saveNewMedicalOrder',
      'editMedicalOrder',
    ]),
    ...mapActions('dbFrontendConfigurations', [
      'getFrontEndConfigurationsForCreateStudy',
    ]),
    goToNextStep(step) {
      switch (step) {
        case 1:
          if (this.medicalOrder.patientIsReceavingTreatment == 'no') {
            this.medicalRecordStep = 3
          } else {
            this.medicalRecordStep = 2
          }
          break

        default:
          this.medicalRecordStep += 1
          break
      }
    },
    async canProceed(step) {
      await this.$v.$touch()
      if (this.isStepValid(step)) {
        this.goToNextStep(step)
      }
    },
    isStepValid(step) {
      switch (step) {
        case 1:
          return !this.$v.patientAvailable.$invalid
        case 2:
          return (
            !this.$v.patientIsReceavingTreatment.$invalid &&
            !this.$v.medicalOrderTreatmentStage.$invalid &&
            !this.$v.medicalOrderDrugsUsedInActualTreatment.$invalid &&
            !this.$v.medicalOrderTreatmentStageDate.$invalid
          )
        case 3:
          return (
            !this.$v.medicalOrderPreviousTreatmentsDate.$invalid &&
            !this.$v.patientIsReceavingPreviousTreatmentLine.$invalid &&
            !this.$v.medicalOrderTumorOrganSitesOfProgressiveDisease.$invalid
          )
        case 4:
          return !this.$v.willingnessToParticipateClinicalTrial.$invalid
        default:
          return false
      }
    },
    isNotInTheFuture(date) {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      let fechaComparar = new Date(date)
      return fechaComparar <= today
    },
    showFirstPageOfMedicalRecord(pageNumber) {
      this.medicalRecordStep = pageNumber
    },
    handleTimeChange(time) {
      this.timeUntilDismissed = time
    },
    setHandlerMedicalOrderPreviousTesting(val) {
      this.medicalOrderPreviousTesting = val
    },
    async forceUpdateForm(val) {
      await Vue.nextTick()
      this.setHandlerMedicalOrderPreviousTesting(val)
      this.$v.medicalOrderPreviousTesting.$touch()
    },
    async setFocusOnOrders() {
      await this.getStudy(this.$store.state.studies.study.id)
      let seguimientoElement = this.$refs.seguimientos
      if (seguimientoElement) {
        seguimientoElement.focus()
      }
    },
    setNewDefaultValue(object, comparisonValue) {
      if (object != undefined) {
        for (var i = 0; i < object.length; i++) {
          if (object[i].value === comparisonValue) return object[i].value
        }
      }
    },
    getFormattedTextFromArray(array) {
      let k = ''
      for (let index = 0; index < array.length; index++) {
        const element = array[index]
        if (k !== undefined) k = k + ' ' + element + ','
      }
      return k
    },
    getTextForPreScreeningLabel(category, value) {
      if (
        this.preScreeningLabels[`${this.currentlySelectedTumor.code}`][
          `${category}`
        ][`${value}`] != undefined
      ) {
        return this.preScreeningLabels[`${this.currentlySelectedTumor.code}`][
          `${category}`
        ][`${value}`]
      } else {
        return value
      }
    },
    isThereACustomName(study_test, code, pharma_id) {
      let setting = this.$store.state.biomarkers.biomarkersSetting.find(
        (setting) => setting.code === code
      )
      let allowedPharma = setting
        ? setting.allowedPharmas.find((pharma) => pharma.id == pharma_id)
        : null

      let custom_setting = allowedPharma
        ? allowedPharma.customSettings.find(
            (cs) =>
              cs.primaryTumorId ==
                this.$store.state.studies.study.primaryTumorId &&
              cs.sampleTypeId == this.$store.state.studies.study.sampleTypeId &&
              cs.diagnosis === this.$store.state.studies.study.patient.diagnosis
          )
        : null
      let custom_name = custom_setting ? custom_setting.customName : null
      if (custom_name) {
        this.$store.studies.study.study_tests[
          this.$store.studies.study.study_tests.indexOf(study_test)
        ].biomarker.name = custom_name
      }
      return study_test
    },
    ...mapActions('panels', [
      'getUmbrella',
      'getGenesForThisPanel',
      'getGenes',
      'getUmbrellas',
    ]),
    ...mapActions('studies', [
      'getStudy',
      'editStudy',
      'setAdittionalInformation',
      'setLabNotes',
      'setSampleNumber',
      'setStudyStatus',
      'setPickupTimeRange',
      'setPickupAdress',
      'setAdittionalEmails',
    ]),
    ...mapActions('results', ['getResults']),

    geneResult(gene_id) {
      return this.results.find((r) => r.id == gene_id)
    },
    getStudyTestBadgeClass(study_test) {
      return `badge badge-pill mr-1 badge-lg badge-${study_test.display_class}`
    },
    canSendEmail() {
      return [
        'partially_completed',
        'completed',
        'completed_and_returned',
      ].includes(this.study.status)
    },
    async save(send_email) {
      this.setLoading(true)
      this.msg = send_email
        ? 'Estudio guardado y mail enviado con éxito'
        : 'Estudio guardado con éxito'

      try {
        await this.editStudy(this.study.id)
        this.setLoading(false)
        this.timeUntilDismissed = this.duration
      } catch (e) {
        this.catchAjaxError(e)
      }
    },
    async uploadFiles(study_test_file_id, study_test) {
      let file = this.$refs[study_test_file_id][0].files[0] //eslint-disable-line no-unused-vars
      const formData = new FormData()
      formData.append('file', file)
      try {
        //eslint-disable-next-line no-unused-vars
        const response = await fetch(
          `/api2/study_test/study-test-result-file/${study_test.id}`,
          {
            method: 'POST',
            body: formData,
          }
        )
      } catch (e) {
        this.alertFormError(e)
      }
    },
    async uploadUmbrellaFile(study_test_file_id, study_test) {
      let file = this.$refs[study_test_file_id].files[0]

      const formData = new FormData()
      formData.append('file', file)
      try {
        //eslint-disable-next-line no-unused-vars
        const response = await fetch(
          `/api2/study_test/umbrella-study-test-result-file/${study_test.id}`,
          {
            method: 'POST',
            body: formData,
          }
        )
      } catch (e) {
        this.alertFormError(e)
      }
    },
    updateUploadedFiles(study_test_id) {
      this.uploaded_files = {
        ...this.uploaded_files,
        [study_test_id]: true,
      }
    },
    getPharmaName(pharma_id) {
      if (!this.pharmas.length) return ''
      return this.pharmas.find((p) => p.id === pharma_id).name
    },

    getStudyResult(data, result_id) {
      let result = null
      data.forEach((val) => (val.id == result_id ? (result = val.name) : null))
      return result
    },
    async getPharmaNameForUmbrella() {
      this.pharmaNameForOptimusTest = this.pharmas.find(
        (ph) => ph.id == this.umbrellaTest.pharmaId
      ).name
    },
  },
}
</script>

<style scoped lang="scss">
.custom-left {
  position: relative;
  left: 12rem;
  bottom: 0.25rem;
}
.button-for-not-navigator
  .btn-group
  > .btn:not(:last-child):not(.dropdown-toggle),
.button-for-not-navigator .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 8rem;
  border-bottom-right-radius: 8rem;
  border-bottom-left-radius: 8rem;
  border-top-left-radius: 8rem;
}
hr {
  opacity: 0;
}
.list-group-item-info {
  $list-group-border-color: #00dc9c;
}
.custom-icon {
  display: inline-block;
  vertical-align: top;
}
.custom-icon-middle {
  display: inline-block;
  vertical-align: middle;
}
.genes-result-list {
  border-radius: 20px;
  text-overflow: ellipsis;
  background-color: white;
  border-color: #00dc9c;
  color: #00dc9c;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.study-header {
  position: sticky;
  top: 60px;
  z-index: 1;
  background-color: white;
}
.study-header.print {
  position: static;
}

.arrow-back {
  font-size: 2rem;
}

.position-relative > h6 {
  position: absolute;
  top: -18px;
  background-color: white;
}

.border-custom {
  border: 1px solid #ccc;
}

tbody td {
  word-break: break-word;
}

.sticky-column {
  position: sticky;
  left: 0;
  background: white;
}

#results-table.table-striped tbody tr:nth-of-type(odd) .sticky-column {
  background: #f2f2f2;
}

#pdfCol {
  min-width: 150px;
}
@media (max-width: 768px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    height: 5px;
  }
}
</style>
